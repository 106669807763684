import React from "react";

const Error = () => {
  return (
    <div className=" min-h-[50vh] flex justify-center items-center text-4xl">
      <h1>404 Page Not found</h1>
    </div>
  );
};

export default Error;
